import React, { useContext } from 'react';
import { AppContext } from '~/providers/AppProvider';
import PropTypes, { any } from 'prop-types';
import Head from 'next/head';
import getConfig from 'next/config';
import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';
import { useRouter } from 'next/router';
import { IS_SERVER } from '~/utils/app';

const defaultValues = {
  title: t`seo.title_default`,
  description: t`seo.description_default`,
  ogImage: t`seo.og_image_default`,
  ogSiteName: 'Boatsetter',
  email: t`seo.shared.og_email`,
};

const { publicRuntimeConfig } = getConfig();

function SEO({
  description = null,
  title = null,
  titleSuffix = true,
  ogImage = null,
  ogType = 'website',
  noIndex = publicRuntimeConfig.APP_ENV !== 'production',
  i18n,
}) {
  const _props = {
    description,
    title,
    titleSuffix,
    ogImage,
    noIndex,
    ogType,
  };
  const router = useRouter();
  const appCtx = useContext(AppContext);

  const finalProps = Object.keys(_props).reduce((acc, propKey) => {
    acc[propKey] = _props[propKey] || defaultValues[propKey];
    return acc;
  }, {});

  const googleTagManager = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${publicRuntimeConfig.GTM}');`;

  let desc = i18n
    ._(finalProps.description)
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(/(<([^>]+)>)/gi, '');

  const maxDescLength = 320;
  desc = desc.length > maxDescLength ? desc.substring(0, maxDescLength - 3) + '...' : desc;

  return (
    <Head>
      {/* {supportedCultureTokens.map((token) => {
                if (
                    token !== `${appCtx.state.lang}-${appCtx.state.culture}` &&
                    !IS_SERVER
                ) {
                    return (
                        <link
                            key={token}
                            rel="alternate"
                            href={`${window?.location.href.replace(
                                `/${appCtx.state.lang}-${appCtx.state.culture}`,
                                `/${token}`
                            )}`}
                            hrefLang={token}
                        />
                    );
                }
            })}

            {!IS_SERVER && (
                <link
                    rel="canonical"
                    href={`${window?.location.href.replace(
                        `/${appCtx.state.lang}`,
                        `/en`
                    )}`}
                />
            )} */}

      <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
      <meta name="HandheldFriendly" content="true" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <title>
        {i18n._(finalProps.title)} {finalProps.titleSuffix ? ' | Boatsetter' : ''}
      </title>
      {finalProps.noIndex === true && <meta name={'robots'} content={'noindex, nofollow'} />}
      <meta name={'description'} content={desc} />
      <meta property={'og:image'} content={i18n._(finalProps.ogImage)} />
      <meta property={'og:description'} content={desc} />
      <meta property={'og:title'} content={i18n._(finalProps.title)} />
      <meta property={'og:type'} content={finalProps.ogType} />
      <meta property={'og:site_name'} content={'Boatsetter'} />

      {!IS_SERVER && <meta property={'og:url'} content={`${window?.location?.host}${router.asPath}`} />}

      <meta property={'og:locale'} content={`${appCtx.state.lang}`} />
      <meta property="og:email" content={finalProps.email} />
      <meta property="fb:app_id" content={publicRuntimeConfig.FACEBOOK_APP_ID} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name={'twitter:title'} content={i18n._(finalProps.title)} />
      <meta name={'twitter:site'} content={'@boatsetter'} />
      <meta name={'twitter:description'} content={desc} />
      <meta name={'twitter:image'} content={i18n._(finalProps.ogImage)} />

      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`${publicRuntimeConfig.ASSETS_PREFIX}/favicons/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="48x48"
        href={`${publicRuntimeConfig.ASSETS_PREFIX}/favicons/android-chrome-48x48.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${publicRuntimeConfig.ASSETS_PREFIX}/favicons/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${publicRuntimeConfig.ASSETS_PREFIX}/favicons/favicon-16x16.png`}
      />
      <link rel="manifest" href={`${publicRuntimeConfig.ASSETS_PREFIX}/favicons/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={`${publicRuntimeConfig.ASSETS_PREFIX}/favicons/safari-pinned-tab.svg`}
        color="#0751c2"
      />
      <link rel="shortcut icon" href={`${publicRuntimeConfig.ASSETS_PREFIX}/favicons/favicon.ico`} />
      <meta name="msapplication-TileColor" content="#0751c2" />
      <meta name="msapplication-config" href={`${publicRuntimeConfig.ASSETS_PREFIX}/favicons/browserconfig.xml`} />
      <meta name="theme-color" content="#0751c2" />

      {/* <script
                type='text/javascript'
                dangerouslySetInnerHTML={{
                    __html: googleTagManager,
                }}
            /> */}
    </Head>
  );
}

SEO.propTypes = {
  title: any,
  titleSuffix: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ogImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ogType: PropTypes.string,
  noIndex: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
};

export default withI18n()(SEO);
